import React from "react";
import { Button } from "reactstrap";
import "./indexTemplate.css";
import { unsupportedMedia } from "../../actions/Uploadaction";
import { useDispatch, useSelector } from "react-redux";
import {
  ALL_LIVEFEEDBACK_DATA,
  FILE_ALREADY_EXISTS,
} from "../../actions/actionTypes";
import Footer from "../view/Footer";

const UnsupportedMedia = ({
  modalColor,
  fileAlreadyExists,
  allFiles,
  setAllFiles,
  allProcessedFiles,
  setAllProcessedFiles
}) => {
  const TechnicalErrorIconSvg = ({ color }) => (
    <svg
      // style={{ margin: "1rem auto", width: "3rem", height: "3rem" }}
      width="40"
      height="40"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill={color} />
      <path
        d="M13.5089 7.5H16.5177L16.0118 18.0685H13.9882L13.5089 7.5ZM15.0266 23.0769C14.5296 23.0769 14.1124 22.9445 13.7751 22.6798C13.4379 22.4003 13.2692 22.062 13.2692 21.6649C13.2692 21.2677 13.4379 20.9367 13.7751 20.672C14.1124 20.3925 14.5296 20.2528 15.0266 20.2528C15.5237 20.2528 15.9319 20.3925 16.2515 20.672C16.571 20.9367 16.7308 21.2677 16.7308 21.6649C16.7308 22.062 16.5621 22.4003 16.2248 22.6798C15.9053 22.9445 15.5059 23.0769 15.0266 23.0769Z"
        fill="#FCFBFB"
      />
    </svg>
  );

  const dispatch = useDispatch();

  // Get already saved liveFeedback responses from store.
  const allLiveFeedbackResponses = useSelector(
    (state) => state.livefeedback?.allLivefeedbackResponses
  );

  const removeFileFromAllFiles = (index) => {
    const temp = [...allFiles];
    temp.splice(index, 1);
    setAllFiles([...temp]);
  };

  const removeProcessedFileFromAllProcessedFiles = (index) => {
    const temp = [...allFiles];
    temp.splice(index, 1);
    setAllProcessedFiles([...temp]);
  };

  const removeLivefeedbackResponseFromAllResponses = (index) => {
    const temp = [...allLiveFeedbackResponses];
    temp.splice(index, 1);
    dispatch({ type: ALL_LIVEFEEDBACK_DATA, payload: [...temp] });
  };

  const handleDelete = () => {
    removeFileFromAllFiles(allFiles.length - 1);
    removeProcessedFileFromAllProcessedFiles(allProcessedFiles.length - 1);
    removeLivefeedbackResponseFromAllResponses(
      allLiveFeedbackResponses.length - 1
    );
  };

  return (
    <div
      className="card-body popup  text-centered p-4 rounded-2"
    
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          flexDirection: "column",
          alignContent: "stretch",
          flexWrap: "nowrap",
          alignItems: "center",
          padding: "0px 20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <div>
            <TechnicalErrorIconSvg
              color={modalColor ? "var(--modal-color)" : "var(--danger-color)"}
            />
          </div>
          <div
            style={{
              fontFamily: "Montserrat",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            {fileAlreadyExists ? "File already exists" : "Unsupported format"}
          </div>
          <div style={{ textAlign: "center" }}>
            <div
              style={{
                fontFamily: "Montserrat",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Please upload a PDF file only.{" "}
            </div>
          </div>

          <Button
            // className=" upload-button font-bold ws-normal-m mb-3 mt-3"
            className="btn cbtn"
            style={{
              // borderRadius: "35px",
              fontFamily: "Montserrat",
              // border: "solid 2px #00bcd4",
              backgroundColor: "black",
              color: "#fff",
              padding: "8px 30px",
              fontSize: "16px",
              fontWeight: "600",
              // width: "100%",
              // margin: "1rem 0rem 2rem 0rem",
            }}
            onClick={(e) => {
              e.stopPropagation();
              // if this popup is for file already exists make fileAlreadyexists key false in the store.
              if (fileAlreadyExists) {
                dispatch({ type: FILE_ALREADY_EXISTS, payload: false });
              }
              // else this popup is for unsupported media and make unsupportedMedia key false in the store.
              else {
                handleDelete();
                dispatch(unsupportedMedia(false));
              }
            }}
          >
            Try Again
          </Button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default UnsupportedMedia;
