import React from "react";
import { useSelector } from "react-redux";
import uploadImage from "../../../images/upload.svg";
import Footer from "../Footer";
import "./index.css";

export default function Upload({
    handleBrowseClick,
    handleDragOver,
    handleDrop,
    handleFileSelect,
    fileInputRef,
}) {
    const lastLinkClickedData = useSelector(
        (state) => state.lastLinkClicked?.dataSuccess
    );

    const getCategory = (category) => {
        if (category === "address") {
            return "utility bill";
        } else {
            return category + " statement";
        }
    };

    return (
        <div
            className="card-body popup  text-centered p-4 rounded-2 popup"
        >
            <div
                className="uploader-col"
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <div>
                    <img src={uploadImage} alt="upload" height={120} />
                </div>
                <div>
                    {lastLinkClickedData?.button?.multidownload
                        ? "Drop your " +
                        lastLinkClickedData?.button?.dataRangeInMonths +
                        " months " +
                        (lastLinkClickedData
                            ? getCategory(lastLinkClickedData?.category)
                            : "satement")
                        :
                        <div className="upload-text-container">
                            <div className="upload-title-text-container">
                            <div className="upload-title-text">Drag & drop or</div>
                            <div onClick={handleBrowseClick} className="upload-title-upload upload-title-text"> click to upload </div>
                            <div className="upload-title-text">{(lastLinkClickedData
                                ? getCategory(lastLinkClickedData?.category)
                                : "statement")}</div>
                                </div>
                            <div className="upload-footer-text-container">
                                <div className="upload-footer-text">Maximum total file size: 5MB</div>
                                <div className="upload-footer-text">File types: .pdf, .jpg, .jpeg, .png, .heic</div>
                            </div>
                        </div>
                    }
                </div>

                <input
                    ref={fileInputRef}
                    className="d-none"
                    type="file"
                    onChange={handleFileSelect}
                    multiple={lastLinkClickedData?.button?.multidownload}
                />
            </div>

            <Footer />
        </div>
    );
}
