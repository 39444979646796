
import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';

export const ProgressWidget = forwardRef((props, ref) => {
  const containerRef = useRef(null);
  const widgetInstance = useRef(null);

  useEffect(() => {
    // Initialize widget only once
    if (containerRef.current && !widgetInstance.current) {
      widgetInstance.current = window.initializeDiroWidget(containerRef.current, {
        isLoading: true,
        message: "Waiting for response...",
        color: "#007bff"
      });
    }
  }, []);

  // Expose the actual method name that exists in loader script
  useImperativeHandle(ref, () => ({
    updateProgressAndMessage: (data) => {
      if (widgetInstance.current) {
        widgetInstance.current.updateProgressAndMessage(data);
      }
    }
  }));

  return <div ref={containerRef} />;
});