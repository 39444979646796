import { PROGRESS_LIVEFEEDBACK_LOADER } from "../actions/actionTypes"
export async function fetchApiWithFormData(url, formData, delayMs,dispatch) {

  const response = await fetch(url, {
    method: 'POST',
    headers: { Accept: 'text/event-stream' },
    body: formData,
  });

  if (!response.body) throw new Error('No response body received');

  const reader = response.body.getReader();
  const decoder = new TextDecoder();
  let buffer = ''; // Collects partial chunks
  const queue = []; // Queue to store parsed messages
  let finalResponse = null;

  while (true) {
    const { value, done } = await reader.read();
    if (done) break;

    buffer += decoder.decode(value); // Add the chunk to the buffer

    let delimiterIndex = buffer.indexOf('\n'); // Look for newline delimiter
    while (delimiterIndex !== -1) {
      const jsonChunk = buffer.slice(0, delimiterIndex).trim(); // Extract one complete chunk
      buffer = buffer.slice(delimiterIndex + 1); // Remove processed chunk from buffer

      if (jsonChunk.startsWith('data:')) {
        const cleanedChunk = jsonChunk.replace(/^data: /, '');

        try {
          const parsed = JSON.parse(cleanedChunk); // Parse the main chunk

          dispatch({ type: PROGRESS_LIVEFEEDBACK_LOADER, payload:{progress:parsed?.progress,message:parsed?.progress === 100 ? "Completed" : parsed?.message}})

          // Add to queue
          queue.push(parsed);

          if (parsed.progress === 100) {
            finalResponse = parsed; // Keep track of the final response
          }
        } catch (err) {
          console.error('Failed to parse JSON chunk:', err);
        }
      }

      delimiterIndex = buffer.indexOf('\n'); // Check for the next delimiter
    }
  }

  // Process the queue with a delay between messages
  await processQueueWithDelay(queue, delayMs);

  if (!finalResponse) throw new Error('Progress never reached 100');
  return finalResponse;
}

async function processQueueWithDelay(queue, delayMs) {
  for (const message of queue) {
    // console.log('Processing message:', message);
    await delay(delayMs);
  }
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}