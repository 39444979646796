import React, { useState, useRef, useEffect, } from "react";
import { Button, Col } from "reactstrap";
import sealImage from "../../assets/Group 1324.png";
import { useDispatch, useSelector } from "react-redux";
import { uploadFileAction } from "../../../actions/Uploadaction";
import "./index.css";

const UploadFile = ({ verifiedData, isVerifying }) => {
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  const params = useSelector((state) => state.upload?.params);

  const lastLinkClicked = useSelector((state) => state.lastLinkClicked);

  const sessionId = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess?.sessionid
  );
  const category = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess?.category
  );

  const handleFileSelect = async (event) => {

    event.preventDefault();
    const file = event.target.files[0] || event.dataTransfer.files[0];

    dispatch(uploadFileAction(file, params, category, sessionId));

    event.target.value = null;
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    dispatch(uploadFileAction(file, params, category, sessionId));
  };

  const handleBrowseClick = () => {
    // Trigger click event on file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <div
        onClick={verifiedData ? handleBrowseClick : null}
        className="card-body popup sealmanage wrapper"
        style={{
          backgroundColor: "rgb(255, 255, 255)",
          opacity: 1,
          border: "dashed 2px rgba(253, 184, 40, 0.5)",
          borderRadius: "4px",
          padding: "0px",
          position: "relative",
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {isVerifying ? (
          <div className="verifying"> Verifying...</div>
        ) : (
          <div
            className={
              verifiedData
                ? "rectangle-210-with-verified-data"
                : "rectangle-210"
            }
          >
            <div
              className="Drag-drop-your-original-PDF-here"
              style={{ color: "#858585" }}
            >
              <div
                className="text-style-1"
                style={
                  verifiedData
                    ? {
                      fontSize: "26px",
                      marginBottom: "0px",
                      fontFamily: "Montserrat",
                    }
                    : {
                      fontSize: "40px",
                      marginBottom: "0px",
                      fontFamily: "Montserrat",
                    }
                }
              >
                {params.buttonid
                  ? lastLinkClicked.dataSuccess?.data?.welcomePage?.gototext
                    ? lastLinkClicked.dataSuccess.data.welcomePage.gototext
                    : `Upload your latest ${category} statement`
                  : "Drag & drop to verify"}
              </div>
              <div
                className="text-style-2"
                style={{ fontSize: "22px", fontFamily: "Montserrat" }}
              >
                {!params || !params.buttonid
                  ?
                  verifiedData
                    ? "another original PDF here"
                    : "your original PDF here"
                  : null}
              </div>
            </div>

            <Col className="text-center mt-5 mb-5">
              {!verifiedData && (
                <Button
                  className="btn cbtn"
                  id="browse"
                  style={{
                    borderRadius: "26px",
                    fontFamily: "Montserrat",
                    border: "solid 2px #00bcd4",
                    backgroundColor: "#00bcd4",
                    color: "#fff",
                    padding: "10px 20px",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                  onClick={handleBrowseClick}
                >
                  Or browse file
                </Button>
              )}
              <input
                ref={fileInputRef}
                className="d-none"
                type="file"
                onChange={handleFileSelect}
              />
            </Col>

            {!verifiedData && (
              <Col className="text-center mb-2">
                <p
                  className="Maximum-combined-file-size-100MB-up-to-50-files"
                  style={{ color: "#292929", fontFamily: "Montserrat" }}
                >
                  Maximum combined file size: 100MB, up to 50 files
                </p>
              </Col>
            )}
          </div>
        )}
        <p style={{ fontSize: "13px", fontFamily: "monospace", fontWeight: "bold", color: "#887a7a", textAlign: "end", marginRight: "1rem" }}> {sessionId && sessionId.toUpperCase()}</p>
        {!sessionId && (
          <div
            className="seal"
            style={{
              position: "absolute",
              bottom: "-60px",
              right: "-40px",
              zIndex: 0,
            }}
          >
            <img
              src={sealImage}
              alt="Seal"
              style={{ width: "7rem", height: "auto" }}
            />
          </div>
        )}
      </div>

    </>
  );
};

export default UploadFile;
