import React from "react";
import "./index.css";
import diroLogo from "../../assets/dirologo.svg";



const Footer = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: "10px",
      }}
    >
      <div
        style={{
          fontSize: "13px",
          fontWeight: 600,
        }}
      >
        Powered by
      </div>
      <img src={diroLogo} height={12} alt="" />
    </div>
  );
};

export default Footer;
