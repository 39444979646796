import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import "./statementUploader.css";
import { Modal, ModalBody, Button } from "reactstrap";
import "./statementUploader.css";
import {
  ALL_LIVEFEEDBACK_DATA,
  LIVEFEEDBACK_INVALID_DOC,
  REDIRECT_TO_UPLOAD,
  SOMETHING_WENT_WRONG,
} from "../../actions/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import TechnicalErrorIconSvg from "../../helpers/technicalError";


var SomethingWentWrong = ({
  allFiles,
  setAllFiles,
  allProcessedFiles, 
  setAllProcessedFiles
}) => {
  const dispatch = useDispatch();

  // Get already saved liveFeedback responses from store.
  const allLiveFeedbackResponses = useSelector(
    (state) => state.livefeedback?.allLivefeedbackResponses
  );

  const removeFileFromAllFiles = (index) => {
    const temp = [...allFiles];
    temp.splice(index, 1);
    setAllFiles([...temp]);
  };

  const removeProcessedFileFromAllProcessedFiles = (index) => {
    const temp = [...allProcessedFiles];
    temp.splice(index, 1);
    setAllProcessedFiles([...temp]);
  };

  const removeLivefeedbackResponseFromAllResponses = (index) => {
    const temp = [...allLiveFeedbackResponses];
    temp.splice(index, 1);
    dispatch({ type: ALL_LIVEFEEDBACK_DATA, payload: [...temp] });
  };

  const handleDelete = () => {
    removeFileFromAllFiles(allFiles.length - 1);
    removeProcessedFileFromAllProcessedFiles(allProcessedFiles.length - 1);
    removeLivefeedbackResponseFromAllResponses(
      allLiveFeedbackResponses.length - 1
    );
  };

  const redirectToUpload = () => {
    
    if (allFiles.length < 2) {
      dispatch({ type: REDIRECT_TO_UPLOAD });
    }
  };

  return (
    // <Modal
    //   isOpen={true}
    //   className={"modal-centered"}
    //   backdropClassName="custom-backdrop"
    //   keyboard={false}
    //   backdrop={true}
    //   size="md"
    //   scrollable={true}
    //   style={{ width: "350px", margin: "auto" }}
    //   contentClassName="modal-content-style"
    // >
    //   <ModalBody className=" model-body">
    <div
    className="card-body popup  text-centered p-4 rounded-2"
 
  >
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "center",
            // padding:"20px"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <div>
              <TechnicalErrorIconSvg />
            </div>
            <div
              style={{
                fontFamily: "Montserrat",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Sorry!
            </div>
            <div
              style={{
                fontFamily: "Montserrat",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              There was a technical error.
            </div>

            <Button
              className="btn cbtn"
              id="browse"
              style={{
                // borderRadius: "35px",
                fontFamily: "Montserrat",
                // border: "solid 2px #00bcd4",
                backgroundColor: "black",
                color: "#fff",
                padding: "8px 30px",
                fontSize: "16px",
                fontWeight: "600",
                // width: "100%",
                // margin: "1rem 0rem 2rem 0rem",
              }}
              onClick={() => {
                dispatch({ type: SOMETHING_WENT_WRONG, payload: false });
                handleDelete();
                redirectToUpload();
              }}
            >
              Try Again
            </Button>
          </div>
        </div>
        </div>
    //   {/* </ModalBody>
    // </Modal> */}
  );
};

export default SomethingWentWrong;
